<template>
  <v-container>
    <div v-html="about" style="text-align: left"></div>
  </v-container>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      about: null
    }
  },
  methods: {
    getData() {
      window.axios
          .get('api/abouts-value/' + this.$route.params.id)
          .then((res) => {
            this.about = res.data.data
          })
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    $route() {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>